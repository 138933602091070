import React from 'react';
import './Header.scss';
import { Config } from '../../models';
import { Logo, LanguageSelector } from '../../components';

export const Header = React.memo(
  ({
    config,
    onLanguageChange,
  }: {
    config: Config;
    onLanguageChange?: (language: string) => void;
  }): JSX.Element => {
    return (
      <header className="header">
        <a
          href={config.appConfig.websiteUrl}
          className="header-logo"
          aria-label="Company logo"
        >
          <Logo logoImage={config.uiConfig.images.logo} />
        </a>

        {config.appConfig.languageSelectorEnabled && (
          <LanguageSelector onLanguageChange={onLanguageChange} />
        )}
      </header>
    );
  },
);
