import React, { useState, useMemo } from 'react';
import './LanguageSelector.scss';
import { translationsService } from '../../services/translations.service';
import { GlobeIcon } from '../GlobeIcon/GlobeIcon';
import { LANGUAGES_MAPPING } from './languages-mapping.const';
import { Dropdown } from '../Dropdown/Dropdown';

interface LanguageSelectorProps {
  onLanguageChange?: (language: string) => void;
}
export const LanguageSelector = React.memo(
  ({ onLanguageChange }: LanguageSelectorProps): JSX.Element => {
    const [currentLang, setCurrentLang] = useState(
      translationsService.getCurrentLanguage(),
    );
    const languagesList = useMemo(
      () =>
        translationsService.getAvailableLanguages().map((lang) => ({
          id: lang,
          name: LANGUAGES_MAPPING[lang],
        })),
      [],
    );

    const changeLanguage = async (lang: string): Promise<void> => {
      setCurrentLang(lang);
      await translationsService.changeLanguage(lang);
      onLanguageChange?.(lang);
    };

    if (languagesList.length <= 1) {
      return <></>;
    }

    return (
      <div className="language-selector">
        <Dropdown
          id="language-selector"
          data={languagesList}
          onSelect={changeLanguage}
          selectedId={currentLang}
          title={currentLang?.toUpperCase()}
          icon={<GlobeIcon className="language-icon" />}
        />
      </div>
    );
  },
);
