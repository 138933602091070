import { z } from 'zod';

export const LanguageScheme = z.record(
  z.string(),
  z
    .object({
      translation: z
        .object({
          header: z.string(),
          content: z.string(),
          successTitle: z.string(),
          success: z.string(),
          errorTitle: z.string(),
          error: z.string(),
          subscribeToAll: z.string(),
          unsubscribeFromAll: z.string(),
          updateButton: z.string(),
          backButton: z.string(),
          subscriptionOptionsTitle: z.string(),
          footerText: z.string().optional(),
          subscribeToAllTitle: z.string().optional(),
          unsubscribeFromAllTitle: z.string().optional(),
          subscriptionsOptionsFooterText: z.string().optional(),
        })
        .strict(),
    })
    .strict(),
);
