export const extractQueryString = (url: string): string => {
  const queryRegex = /[?&]([^=#&]+)=([^&#]*)/g;

  let match: RegExpExecArray | null;
  const params: string[] = [];

  while ((match = queryRegex.exec(url)) !== null) {
    params.push(`${match[1]}=${match[2]}`);
  }

  return params.join('&');
};

export const isValidBaseUrlWithQuery = (url: string): boolean => {
  const regex = /^https?:\/\/[^\/?#]+(?:\/result|\/error|\/)?\?([^#]*)$/;
  return regex.test(url);
};
