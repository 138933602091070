import React, { useEffect, useState } from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useLocation,
} from 'react-router-dom';
import { HomePage, GenericErrorPage, ResultPage } from './pages';
import { configurationService } from './services/configuration.service';
import { Config } from './models';
import { Loading } from './components/Loading/Loading';
import { extractQueryString, isValidBaseUrlWithQuery } from './utils';

enum AppState {
  Loading,
  Loaded,
  Failed,
}

// Wrapper component to handle redirection with query parameters
const RedirectWithParams = (): JSX.Element => {
  const location = useLocation();
  return <Navigate to={`/${location.search}`} />;
};

export const App = React.memo((): JSX.Element => {
  const [state, setState] = useState(AppState.Loading);
  const [config, setConfig] = useState<Config>();
  const [isRedirecting, setIsRedirecting] = useState(true);

  const checkRedirect = (): void => {
    const currentUrl = window.location.href;
    const queryString = extractQueryString(currentUrl);

    if (isValidBaseUrlWithQuery(currentUrl)) {
      setIsRedirecting(false);
    } else {
      window.location.href = `/?${queryString}`;
    }
  };

  const initConfigs = async (): Promise<void> => {
    try {
      await configurationService.loadConfig();
      setConfig(configurationService.getConfig());
      setState(AppState.Loaded);
    } catch (err) {
      setState(AppState.Failed);
    }
  };

  useEffect(() => {
    checkRedirect();
  }, []);

  useEffect(() => {
    if (!isRedirecting) {
      void initConfigs();
    }
  }, [isRedirecting]);

  if (isRedirecting || state === AppState.Loading) {
    return <Loading />;
  }

  if (state === AppState.Failed) {
    return <GenericErrorPage />;
  }

  return (
    <RouterProvider
      router={createBrowserRouter([
        {
          path: '/',
          element: config ? <HomePage config={config} /> : <div></div>,
        },
        {
          path: 'error',
          element: <GenericErrorPage />,
        },
        {
          path: 'result',
          element: config ? <ResultPage config={config} /> : <div></div>,
        },
        {
          path: '*',
          element: <RedirectWithParams />,
        },
      ])}
    />
  );
});
